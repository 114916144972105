@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

body {
  font-family: 'Inter';
}

html,
body,
#root,
.App {
  height: 100%;
}

.h-full-with-navbar {
  height: calc(100% - 4rem);
}
