.react-aria-TimeField {
  color: var(--text-color);
}

.react-aria-DateInput {
  display: flex;
  padding: 4px;
  border: 1px solid gray;
  border-radius: 6px;
  background: white;
  width: fit-content;
  min-width: 150px;
  white-space: nowrap;
  forced-color-adjust: none;

  &[data-focus-within] font-normal {
    outline: 2px solid black;
    outline-offset: -1px;
  }
}

.react-aria-DateSegment {
  padding: 0 2px;
  font-variant-numeric: tabular-nums;
  text-align: end;

  &[data-type='literal'] {
    padding: 0;
  }

  &[data-placeholder] {
    font-style: italic;
  }

  &:focus {
    color: white;
    background: gray;
    outline: none;
    border-radius: 4px;
    caret-color: transparent;
  }
}
